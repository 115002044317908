<script>
  export let hero;
</script>

<main>
  <div>
    <img src={hero.imgUrl} alt={hero.name} />
    <p>{hero.name}</p>
  </div>
</main>

<style>
</style>
